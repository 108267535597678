// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orange{
    background-color: #f8ab0f;
}
.bg-darkBlue{
    background-color: #1a2e45;
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/colors.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".orange{\n    background-color: #f8ab0f;\n}\n.bg-darkBlue{\n    background-color: #1a2e45;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
