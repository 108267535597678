// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
  .d-none-767 {
    display: none;
  }
}
@media (max-width: 1100px) {
  .fontCard {
    font-size: 0.5rem;
  }
}
@media (min-width: 1101px) {
  .fontCard {
    font-size: 0.9rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/mediaQuery.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media (max-width: 767px) {\n  .d-none-767 {\n    display: none;\n  }\n}\n@media (max-width: 1100px) {\n  .fontCard {\n    font-size: 0.5rem;\n  }\n}\n@media (min-width: 1101px) {\n  .fontCard {\n    font-size: 0.9rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
